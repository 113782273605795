// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("jquery");
require("bootstrap");
require("tempusdominus-bootstrap-4");
require("moment/locale/ja");
require("@rails/ujs").start();
require("turbolinks").start();
require("select2");
require("@rails/activestorage").start();
require("channels");

// stylesheets
require("../stylesheets/application.scss");

import "@fortawesome/fontawesome-free/css/all.css";
import "./owl.carousel.min.js";
import "select2";
import "select2/dist/css/select2.css";

$(document).on("ready turbolinks:load", function () {
  $(".customers-dropdown").select2();
});

$(function () {
  $("select").on("change", function (event) {
    $("#extra_price").html(
      formatCurrency($("#extra_" + $(".select").val()).html())
    );
    computetotal(event);
  });

  $("#inlineDatepicker").datetimepicker({
    inline: true,
    sideBySide: false,
    format: "L",
    useCurrent: false,
    defaultDate: $("#weekStardate").val(),
  });

  $("#inlineDatepicker").on("change.datetimepicker", ({ date, oldDate }) => {
    var fdate = moment(date);
    $("#weekStardate").val(fdate.format("D/M/YYYY"));
  });

  $("#inlineDatepicker1").datetimepicker({
    inline: true,
    sideBySide: false,
    format: "L",
    useCurrent: false,
    defaultDate: $("#holidayStartdate").val(),
  });

  $("#inlineDatepicker1").on("change.datetimepicker", ({ date, oldDate }) => {
    var fdate = moment(date);
    $("#holidayStartdate").val(fdate.format("D/M/YYYY"));
  });

  $("#inlineDatepicker2").datetimepicker({
    inline: true,
    sideBySide: false,
    format: "L",
    useCurrent: false,
    defaultDate: $("#holidayCutoffdate").val(),
  });

  $("#inlineDatepicker2").on("change.datetimepicker", ({ date, oldDate }) => {
    var fdate = moment(date);
    $("#holidayCutoffdate").val(fdate.format("D/M/YYYY"));
  });

  $("#inlineDatepicker3").datetimepicker({
    inline: true,
    sideBySide: false,
    format: "L",
    useCurrent: false,
    defaultDate: $("#holidayDeliverydate").val(),
  });

  $("#inlineDatepicker3").on("change.datetimepicker", ({ date, oldDate }) => {
    var fdate = moment(date);
    $("#holidayDeliverydate").val(fdate.format("D/M/YYYY"));
  });
});

$(document).on("scroll", function (e) {
  var scrollTop = $(document).scrollTop();
  if (scrollTop > 50) {
    $(".main-header").addClass("sticky");
    $(".logo").css("width", "50%");
    $(".nav-link").css("line-height", "3");
  } else {
    $(".main-header").removeClass("sticky");
    $(".logo").css("width", "65%");
    $(".nav-link").css("line-height", "5");
  }
});
